import React from 'react'
import Paths from '../../../Paths'
import { H2, P } from '../../../../shared/components'
import { LeftCol, RightCol, SectionRow } from '../../Styles'
import { HashLink as Link } from 'react-router-hash-link'

export const Introduction = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <H2>Equipment Status API - Webhook events</H2>

        <P size="small">
          The Equipment Status API provides you information as webhook events about the availability, equipment status
          data and movement status of KONE and NON-KONE equipments. Webhook subscriptions are managed using{' '}
          <Link to={Paths.WebhookManagementAPIDocsForKoneAPI.path}>Webhook Callback API</Link>
        </P>
      </LeftCol>
      <RightCol span="12"></RightCol>
    </SectionRow>
  )
}
