import React, { FC } from 'react'
import {
  CONTRACT_EXPIRY_CRITICAL_LIMIT,
  CONTRACT_EXPIRY_MAX_LIMIT,
  CONTRACT_EXPIRY_WARNING_LIMIT,
} from '../../../shared/constants/common'
import { ResourceFlagOrange, ResourceFlagRed, ResourceFlagYellow } from './Styles'
interface getResourceExpiryFlagProps {
  expiredDate: string[]
}
export const GetResourceExpiryFlag: FC<getResourceExpiryFlagProps> = ({ expiredDate }) => {
  console.log('entered!!!')
  if (!expiredDate) {
    return null
  }
  const dates: Date[] = []
  if (expiredDate) {
    expiredDate?.map((dateStr: string) => {
      const dateArray = dateStr?.split('--')
      if (dateArray?.length > 0 && dateArray[1]?.length > 0) {
        return dates?.push(new Date(dateStr?.split('--')[1]))
      }
      return true
    })
  }

  if (dates?.length > 0) {
    const sorted = dates.sort((a: Date, b: Date) => {
      return a.getTime() - b.getTime()
    })
    /**
       * yellow < 60 , > 30 MAX_LIMIT
        orange < 30  WARNING_LIMIT
        red < 0   CRITICAL_LIMIT
      */
    if (sorted?.length > 0) {
      const minDate = sorted[0]
      const now = new Date()
      const diffTime: number = minDate.getTime() - now.getTime()
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      console.log('days::', diffDays)

      const titleMsg = `Contract about to expire in ${diffDays} days!`
      if (diffDays < 0) {
        return <ResourceFlagRed title={`Contract Expired!`} />
      } else if (diffDays >= CONTRACT_EXPIRY_CRITICAL_LIMIT && diffDays < CONTRACT_EXPIRY_WARNING_LIMIT) {
        return <ResourceFlagOrange title={titleMsg} />
      } else if (diffDays >= CONTRACT_EXPIRY_WARNING_LIMIT && diffDays < CONTRACT_EXPIRY_MAX_LIMIT) {
        return <ResourceFlagYellow title={titleMsg} />
      }
    }
  }

  return null
}
