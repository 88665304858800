import React, { FC, useState } from 'react'
import { DownloadsAPI } from '../../../../shared/api/downloads'
import { DownloadButtonContainer, DownloadButtonName, DownloadTittle } from './style'
import { Row, Col } from 'antd'
import { Toast } from '../../../../shared/components'
import { ReactComponent as DownloadIcon } from '../../../../assets/icon-download.svg'

type DownloadFileButtonProps = {
  fileName: string
  buttonName: string
  onDownloadStart: () => void
  onDownloadFailed: () => void
}

const DownloadFileButton: FC<DownloadFileButtonProps> = ({
  fileName,
  buttonName,
  onDownloadStart,
  onDownloadFailed,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const onDownloadClick = async () => {
    setIsLoading(true)
    onDownloadStart()

    try {
      const downloadUrl = await DownloadsAPI.getDownloadURL(fileName)
      const temporaryLink = document.createElement('a')
      temporaryLink.setAttribute('href', downloadUrl)
      temporaryLink.setAttribute('download', fileName)

      temporaryLink.style.display = 'none'
      document.body.appendChild(temporaryLink)

      temporaryLink.click()

      document.body.removeChild(temporaryLink)
    } catch (err) {
      console.error(err)
      onDownloadFailed()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <DownloadButtonContainer>
      <DownloadIcon />
      <DownloadButtonName onClick={onDownloadClick}>{buttonName}</DownloadButtonName>
    </DownloadButtonContainer>
  )
}

const ProductionAccess: FC = () => {
  const [isShowDownloadError, setIsShowDownloadError] = useState(false)

  const onDownloadStart = () => {
    setIsShowDownloadError(false)
  }

  const onDownloadFailed = () => {
    setIsShowDownloadError(true)
  }

  // TODO: Delete V1 docs from "manual-prod-docs" s3 bucket once deployed to production.
  return (
    <>
      <Toast
        key="download-error"
        isVisible={isShowDownloadError}
        text="Download file error. Please try again."
        onDismiss={() => setIsShowDownloadError(false)}
        type="error"
      />
      <Row>
        <Col xs={2} sm={4} md={6} lg={8} xl={6}>
          <DownloadFileButton
            fileName="Elevator Call API solution validation test guide version 2.docx"
            buttonName="Elevator Call API"
            onDownloadStart={onDownloadStart}
            onDownloadFailed={onDownloadFailed}
          />
        </Col>
        <Col xs={2} sm={4} md={6} lg={8} xl={6}>
          <DownloadFileButton
            fileName="Service Robot API solution validation test guide version 2.docx"
            buttonName="Service Robot API"
            onDownloadStart={onDownloadStart}
            onDownloadFailed={onDownloadFailed}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={2} sm={4} md={6} lg={8} xl={6}>
          <DownloadFileButton
            fileName="Service Info API solution validation test guide version 2.docx"
            buttonName="Service Info API"
            onDownloadStart={onDownloadStart}
            onDownloadFailed={onDownloadFailed}
          />
        </Col>
        <Col xs={2} sm={4} md={6} lg={8} xl={6}>
          <DownloadFileButton
            fileName="Equipment Status API solution validation test guide version 2.docx"
            buttonName="Equipment Status API"
            onDownloadStart={onDownloadStart}
            onDownloadFailed={onDownloadFailed}
          />
        </Col>
      </Row>

      <DownloadTittle>Generic sequence diagram</DownloadTittle>
      <p>
        {' '}
        This information document that can help you to understand the sequence and frame the proper end-end flow with
        your solution.
      </p>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <DownloadFileButton
            fileName="Experience_API_v2 SequenceDiagram template.pdf"
            buttonName="Experience API (Elevator Call API & Service Robot API)"
            onDownloadStart={onDownloadStart}
            onDownloadFailed={onDownloadFailed}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <DownloadFileButton
            fileName="Operational_API_v2 SequenceDiagram template.pdf"
            buttonName="Operational API (Equipment status API & Service Info API)"
            onDownloadStart={onDownloadStart}
            onDownloadFailed={onDownloadFailed}
          />
        </Col>
      </Row>
    </>
  )
}

export default ProductionAccess
