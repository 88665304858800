export const ClientInfoStrings = {
  clientSecretInfo: 'Important! Your Client Secret will not be shown again, store it somewhere safe!',
  appCreatedSuccessMessage: 'App has been created!',
  clientId: 'Client id',
  clientSecret: 'Client secret',
  availableApis: 'Available APIs',
  documentation: 'documentation',
  backToApplications: 'back to dashboard',
}

export const CreateFormStrings = {
  noProductionText: `Production access is available for those applications that are used by KONE maintenance service customers with
    KONE API Services contract.`,
  contactUsButton: 'contact us',
  sandBoxRadioButton: 'Sandbox',
  productionRadioButton: 'Production',
  appName: 'App Name',
  appNameRequiredError: 'Application name is required.',
  appDescription: 'Description',
  createApplication: 'create application',
  createApplicationError: 'Create application error',
  appNameLengthError: 'App name must be between 1 to 64 characters',
  appNameWhiteSpaceError: 'Whitespace is not allowed at the begnining and end of the app name',
  createApplicationErrorLimitSandboxPersonal: 'you can only have 2 personal sandbox applications',
  createApplicationErrorLimitSandboxExt: 'you can have only 1 sandbox application per organization',
  createApplicationErrorLimitProd: 'production applications limit reached',
}

export const CreateWebhookForm = {
  webhookName: "Webhook's name",
  webhookTargetURL: 'Target URL',
  webhookContactEmail: 'Contact email',
  subscribeResourceIds: 'Selected equipments in subscription',
  subscribeResourceIdMessage:
    'Select equipment to receive events. If no equipment selected, events regarding all equipments will be delivered ',
  webhookSecret: 'Set the secret',
  webhookCheckboxTestEvent: 'This is the test event type',
  webhookCheckboxMovementCompletedEvent: 'This is the elevator movement completed type',
  webhookCheckboxMovementStartedEvent: 'This is the elevator movement started type',
  webhookCheckboxAvailabilityEvent: 'This is the equipment availability event type',
  webhookCheckboxServiceOrderEvent: 'This is the equipment related service order event type',
  webhookCheckboxEscMovementChangeEvent: 'This is the escalator movement event type',
  webhookCheckboxServiceStatusEvent: 'This is the equipment status event type',
  webhookCheckboxElvDoorEvent: 'This is the elevator door event type',
  webhookCheckboxElvButtonEvent: 'This is the elevator button event type',
  webhookCheckboxMovCompTwoEvent: 'This is the elevator movement completed type',
  createWebhook: 'create webhook',

  webhookUrlRequiredError: 'Webhook endpoint is required.',
  webhookTypesRequiredError: 'At least one subscription should be selected.',
  webhookSecretRequiredError: 'Endpoint secret is required.',
  webhookNameLengthError: 'Webhook name must be between 1 to 64 characters',
  webhookNameRequired: 'Webhook name is required',
  webhookNameWhiteSpaceError: 'Whitespace is not allowed at the beginning and end of the app name',
  webhookSelectAll: 'Select All',
}

export const EditWebhookForm = {
  title: 'Name and Connection',
  webhookName: "Webhook's name",
  webhookTargetURL: 'Target URL',
  email: 'Contact Email',
  subscriptionStatus: 'Subscription Status',
  webhookSecret: 'Secret Token',
  enabled: 'Enabled',
  disabled: 'Disabled',
  subscribeResourceIds: 'Selected equipments in subscription',
  webhookCheckboxTestEvent: 'This is the test event type',
  webhookCheckboxMovementCompletedEvent: 'This is the elevator movement completed type',
  webhookCheckboxMovementStartedEvent: 'This is the elevator movement started type',
  webhookCheckboxAvailabilityEvent: 'This is the equipment availability event type',
  webhookCheckboxServiceOrderEvent: 'This is the service order event type',
  webhookCheckboxServiceStatusEvent: 'This is the equipment status event type',
  webhookCheckboxElvDoorEvent: 'This is the elevator door event type',
  webhookCheckboxElvButtonEvent: 'This is the elevator button event type',
  webhookCheckboxEscMovementChangeEvent: 'This is the escalator movement event type',
  deleteWebhook: 'Delete webhook',
  saveChanges: 'Save Changes',

  webhookUrlRequiredError: 'Webhook endpoint is required.',
  webhookTypesRequiredError: 'At least one subscription should be selected.',
  webhookSecretRequiredError: 'Endpoint secret is required.',
  webhookNameLengthError: 'Webhook name must be between 1 to 64 characters',
  webhookNameWhiteSpaceError: 'Whitespace is not allowed at the beginning and end of the app name',
  webhookDeleteError: 'Error in deleting the subscription',
  webhookConfirmDeletion: 'Confirm delete',
  webhookCancelDeletion: 'Cancel',
  webhookDeletePopupText: 'Please confirm you want to delete subscription',
}

export const CreateWizardStrings = {
  createAppStep: 'Create app',
  createAppDescription: 'Add basic information',
  generateSecret: 'Generate secret',
  generateSecretDescription: 'Client ID and Client secret are automatically generated',
  appCreatedTitle: 'App created',
  appCreatedDescription: 'Remember to store the client secret somewhere safe!',
}

export const EditAppStrings = {
  appRemovedSuccess: 'App has been removed!',
  resetSecretSuccess: 'Important! Your Client Secret will not be shown again, store it somewhere safe!',
  resetSecretError: 'Reset secret error',
  clientId: 'Client ID',
  modalTitle: 'Please note!',
  removeAppButton: 'Yes, remove',
  cancelRemoveButton: `No, let's go back`,
  confirmDelete: `Please confirm you want to delete:`,
  regenerateButton: 'Yes, regenerate',
  confirmRegenerate: 'Please confirm you want to regenerate the secret',
  removeAppError: 'Remove application error',
}

export const EditAppDeleteStrings = {
  removeApp: 'remove app',
}

export const GenerateSecretStrings = {
  somethingWentWrongError: 'Something went wrong!',
  clientSecretError: 'There was an error while generating the client secrets. Please try again.',
  tryAgainMessage: 'Try again',
}

export const TileCreateStrings = {
  createFirstApplication: 'Create first application',
  createNewApplication: 'Create new application',
  createApplication: 'Create application',
}

export const TileInfoStrings = {
  envTextSandbox: 'sandbox',
  envTextProduction: 'production',
  editButton: 'Edit',
  noDescription: 'No description provided',
}

export const SharedApplicationStrings = {
  clientId: 'Client ID',
  clientSecret: 'Client secret',
  reset: 'Reset',
  copied: 'Copied',
}
