export enum EventType {
  'TestV1' = 'com.kone.test.v1',
  'SubscriptionUpdateV1' = 'com.kone.subscription.v1',
  'ElevatorCallCreatedV1' = 'com.kone.elevator.call.created.v1',
  'ElevatorMovementCompletedV1' = 'com.kone.elevator.movement.completed.v1',
  'ElevatorMovementStartedV1' = 'com.kone.elevator.movement.started.v1',
  'EquipmentAvailabilityV1' = 'com.kone.equipment.availability.v1',
  'EquipmentStatusV1' = 'com.kone.equipment.status.v1',
  'ServiceOrderV2' = 'com.kone.equipment.serviceorder.v2',
  'EscalatorMovementChangeV1' = 'com.kone.escalator.movement.change.v1',
  'ElevatorDoorEventV1' = 'com.kone.elevator.door.event.v1',
  'ElevatorButtonEventV1' = 'com.kone.elevator.button.event.v1',
  'ElevatorMovementCompletedV2' = 'com.kone.elevator.movement.completed.v1',
}
