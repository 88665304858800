import React, { FC } from 'react'
import {
  CardContainer,
  BuildingTopologyContainer,
  ContentHeading,
  BuildingTopologyImageContainer,
  BuildingOfficeSvg,
  VirtualBuildingButtonText,
  VirtualBuildingButton,
  VirtualBuildingButtonContainer,
  PopUpContainer,
  PopUpTextContainer,
  ImagePreview,
  BuildingTopologyPopUpTextContainer,
  ImagePreviewBuilding4,
  RightArrowContainer,
} from './Styles'
import { Row, Col } from 'antd'
import Modal from '../../../../shared/components/ModalPopUp'
import { BuildingsStrings } from '../../../../shared/strings/BuildingsContent'
import virtualBuilding1 from '../../../../shared/assets/img-virtual-building-1.png'
import virtualBuilding2 from '../../../../shared/assets/img-virtual-building-2.png'
import virtualBuilding3 from '../../../../shared/assets/img-virtual-building-3.png'
import virtualBuilding4 from '../../../../shared/assets/img-virtual-building-4.png'

const BuildingTopology: FC = () => {
  const [openVirtualBuilding1, setOpenVirtualBuilding1] = React.useState(false)
  const [openVirtualBuilding2, setOpenVirtualBuilding2] = React.useState(false)
  const [openVirtualBuilding3, setOpenVirtualBuilding3] = React.useState(false)
  const [openVirtualBuilding4, setOpenVirtualBuilding4] = React.useState(false)

  const closeVirtualBuilding1 = () => {
    setOpenVirtualBuilding1(false)
  }

  const openVirtualBuilding1PopUp = () => {
    setOpenVirtualBuilding1(true)
  }

  const openVirtualBuilding2PopUp = () => {
    setOpenVirtualBuilding2(true)
  }

  const closeVirtualBuilding2 = () => {
    setOpenVirtualBuilding2(false)
  }

  const openVirtualBuilding3PopUp = () => {
    setOpenVirtualBuilding3(true)
  }

  const closeVirtualBuilding3 = () => {
    setOpenVirtualBuilding3(false)
  }

  const openVirtualBuilding4PopUp = () => {
    setOpenVirtualBuilding4(true)
  }

  const closeVirtualBuilding4 = () => {
    setOpenVirtualBuilding4(false)
  }
  return (
    <>
      <CardContainer>
        <Row justify="center" align="middle">
          <Col xs={2} sm={4} md={6} lg={8} xl={24}>
            <ContentHeading>Building Topology</ContentHeading>
            <BuildingTopologyImageContainer>
              <BuildingTopologyContainer />
            </BuildingTopologyImageContainer>
            <Row justify="end" align="middle">
              <Col xs={2} sm={4} md={6} lg={8} xl={6}>
                Click here for more info
              </Col>
              <RightArrowContainer />
              <VirtualBuildingButtonContainer xs={2} sm={4} md={6} lg={8} xl={13}>
                <VirtualBuildingButton onClick={openVirtualBuilding1PopUp}>
                  <BuildingOfficeSvg />
                  <VirtualBuildingButtonText>Virtual Building 1</VirtualBuildingButtonText>
                </VirtualBuildingButton>
                <VirtualBuildingButton onClick={openVirtualBuilding2PopUp}>
                  <BuildingOfficeSvg />
                  <VirtualBuildingButtonText>Virtual Building 2</VirtualBuildingButtonText>
                </VirtualBuildingButton>
                <VirtualBuildingButton onClick={openVirtualBuilding3PopUp}>
                  <BuildingOfficeSvg />
                  <VirtualBuildingButtonText>Virtual Building 3</VirtualBuildingButtonText>
                </VirtualBuildingButton>
                <VirtualBuildingButton onClick={openVirtualBuilding4PopUp}>
                  <BuildingOfficeSvg />
                  <VirtualBuildingButtonText>Virtual Building 4</VirtualBuildingButtonText>
                </VirtualBuildingButton>
              </VirtualBuildingButtonContainer>
            </Row>
          </Col>
        </Row>
      </CardContainer>
      <Modal
        key="virtual-building-1"
        title={BuildingsStrings.note}
        isOpen={openVirtualBuilding1}
        onClose={closeVirtualBuilding1}
      >
        <PopUpContainer>
          <PopUpTextContainer>
            <BuildingTopologyPopUpTextContainer>
              {`"Virtual building fWlfHyPlaca is an 8 cars group single deck elevator with all cars serving all 40 floors
              both front and rear side. This is efficient in testing basic calls for mid rise buildings"`}
            </BuildingTopologyPopUpTextContainer>
            <ImagePreview src={virtualBuilding1} />
          </PopUpTextContainer>
        </PopUpContainer>
      </Modal>
      <Modal
        key="virtual-building-2"
        title={'Please note !'}
        isOpen={openVirtualBuilding2}
        onClose={closeVirtualBuilding2}
      >
        <PopUpContainer>
          <PopUpTextContainer>
            <BuildingTopologyPopUpTextContainer>
              {`"Virtual building 4TFxWRCv23D is an 8 cars group double deck elevator with all cars and decks serving all
              63 floors both front and rear side. This is efficient in testing basic calls for high rise buildings"`}
            </BuildingTopologyPopUpTextContainer>
            <ImagePreview src={virtualBuilding2} />
          </PopUpTextContainer>
        </PopUpContainer>
      </Modal>
      <Modal
        key="virtual-building-3"
        title={BuildingsStrings.note}
        isOpen={openVirtualBuilding3}
        onClose={closeVirtualBuilding3}
      >
        <PopUpContainer>
          <PopUpTextContainer>
            <BuildingTopologyPopUpTextContainer>
              {`"Virtual building HxKjGc3knnh is an 8 cars group single deck elevator with all cars serving all 40 floors
              both front and rear side. This is efficient in testing basic calls for mid rise buildings"`}
            </BuildingTopologyPopUpTextContainer>
            <ImagePreview src={virtualBuilding3} />
          </PopUpTextContainer>
        </PopUpContainer>
      </Modal>
      <Modal
        key="virtual-building-4"
        title={BuildingsStrings.note}
        isOpen={openVirtualBuilding4}
        onClose={closeVirtualBuilding4}
      >
        <PopUpContainer>
          <PopUpTextContainer>
            <BuildingTopologyPopUpTextContainer>
              {`"Virtual building L1QinntdEOg is an 8 cars group double deck elevator with 4 cars serving lower 33 floors
              both front and rear side and 4 cars serving upper floors from 32 to 63 both front and rear. All 8 cars
              have floors 1, 32 and 33 in common as transfer floors. This is efficient in testing basic calls for low
              rise buildings, multiple rise buildings, transfer floor calls and high rise buildings"`}
            </BuildingTopologyPopUpTextContainer>
            <ImagePreviewBuilding4 src={virtualBuilding4} />
          </PopUpTextContainer>
        </PopUpContainer>
      </Modal>
    </>
  )
}

export default BuildingTopology
