import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogFeb25 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        Latest Release - February 2025
      </H4>
      February release notes summarizes latest changes for Equipment Info API, API Portal changes and Service Info API.
      <P style={{ fontWeight: 'bold' }}>What is new here:</P>
      <OL size="small">
        <li style={{ marginTop: '30px' }}>Equipment Info API</li>
        <UL>
          <li>
            <q>time­Stamp</q> is now available in both
            <Link to={Paths.EquipmentStatus2RestAPIDocs.path + '#getEquipmentMovementByIds'}>
              {' '}
              Equipment Movement Information REST API
            </Link>{' '}
            and{' '}
            <Link to={Paths.EquipmentStatus2WebhookEvents.path + '#movement-event'}>
              {' '}
              Equipment Movement information Webhook API
            </Link>{' '}
            .
          </li>
          <li>
            The description of <q>time</q> is updated in{' '}
            <Link to={Paths.EquipmentStatus2WebhookEvents.path + '#movement-event'}>
              {' '}
              Equipment Movement information Webhook API
            </Link>{' '}
            .
          </li>
          <li>
            <q>stopMode</q> has been updated with additional values in{' '}
            <Link to={Paths.EquipmentStatus2WebhookEvents.path + '#movement-event'}>
              {' '}
              Equipment Movement information Webhook API
            </Link>{' '}
            .
          </li>
        </UL>
        <li style={{ marginTop: '30px' }}>API Portal</li>
        <UL>
          <li>
            The image of Virtual Building 4 has been updated with the correct configuration inside{' '}
            <Link to={Paths.SolutionValidation.path}>Solution Validation</Link>.
          </li>
          <li>
            <li>Updated Title and Description of API Products inside KONE APIs and API documentation section.</li>
            <li>
              Updated the names of API Products in the Resources section of the{' '}
              <Link to={Paths.OrganizationDetails.path}>Organization details </Link> .
            </li>
            <li>
              Updated overview inside Kone APIs section for{' '}
              <Link to={'/equipment-status-api/#koneapi-equipment-status'}>Equipment Status API</Link> and{' '}
              <Link to={'/service-info-api/#koneapi-service-info'}>Service Info API</Link>.
            </li>
          </li>
        </UL>

        <li style={{ marginTop: '30px' }}>Service Info API</li>
        <UL>
          <li>
            Added description for each <q>activityType</q> in{' '}
            <Link to={Paths.ServiceInfoWebhookAPIApiPageDocs.path}> Service Info Webhook API </Link> and{' '}
            <Link to={Paths.ServiceInfoAPIV2Docs.path + '#getServiceOrderById'}> Service Info REST API</Link> .
          </li>
        </UL>
      </OL>
    </>
  )
}
