import React, { FC, useState, useContext, useEffect } from 'react'
import { Button } from 'antd'
import { PartnerP, RequiredFieldsText } from '../Styles'
import { FormButton, StyledLine } from './Styles'
import { PartnerStrings } from '../../../../shared/strings/PartnersContent'
import { Solution } from '../../Models'
import OneSolution from './OneSolution'
import { PartnerContext } from '../state'
import { Toast } from '../../../../../src/shared/components'
import { checkDuplicateSolutions, isValidInput } from '../../../../../src/shared/utils/salespage'

interface SolutionInfoProps {
  next: (data: any | null) => void
  defaultSolution?: Solution
}

const SolutionInfo: FC<SolutionInfoProps> = (props) => {
  const { defaultSolution } = props
  const [solutionList, setSolutionList] = useState<any[]>([{ ...defaultSolution }])
  const { partner, setPartner, masterData } = useContext(PartnerContext)
  const [solutionDisabled, setSolutionDisabled] = useState(false)
  const [isSuccess, setIsSuccess] = useState<boolean | false>(false)
  const [successMsg, setsuccessMsg] = useState<string | ''>('')
  const defaultError = {
    solutionName: {
      error: false,
      message: 'Please enter solution name',
    },
    apis: {
      error: false,
      message: 'Please select a API',
    },
    keyCustomerSegments: {
      error: false,
      message: 'Please select a key customer segment',
    },
    country: {
      error: false,
      message: 'Please select a country',
    },
    category: {
      error: false,
      message: 'Please select a category',
    },
    solutionHighlights: {
      error: false,
      message: 'Please select enter solutionHighlights',
    },
  }
  const [formErr, setFormErr] = useState<any>([{ ...defaultError }])
  const { ...restProps } = props

  const checkTouched = (index: number, key: string, value: string) => {
    if (formErr[index]?.[key].error === true && value && value?.length > 0) {
      const newFormErr = [...formErr]
      if (newFormErr[index]?.[key].error) {
        newFormErr[index][key].error = false
        setFormErr(newFormErr)
      }
    }
  }

  useEffect(() => {
    if (solutionList) {
      const hasDuplicateSolution = checkDuplicateSolutions(solutionList, 'solutionName')
      if (hasDuplicateSolution) {
        setsuccessMsg(PartnerStrings.uniqueSolutionName)
        setIsSuccess(true)
      } else {
        setIsSuccess(false)
      }
    }
  }, [solutionList])

  const onInputChange = (e: any, index: number, key: string) => {
    const { value } = e.target

    const tempSolutions: Solution[] = [...solutionList]
    tempSolutions[index] = { ...tempSolutions[index], [key]: value }

    checkTouched(index, key, value)
    setSolutionList(tempSolutions)
  }

  const isDisableSolutionButton = () => {
    const flagArr: boolean[] = []
    solutionList?.map((solution: Solution, index: number) => {
      const isDisable =
        solution?.solutionName?.trim()?.length == 0 ||
        solution?.solutionName?.trim()?.length > 64 ||
        solution?.apis?.length == 0 ||
        solution?.category?.length == 0 ||
        solution?.country?.length == 0 ||
        solution?.keyCustomerSegments?.length == 0 ||
        solution?.solutionHighlights?.trim()?.length == 0 ||
        (index === 1 && solutionList[0]?.solutionName === solution.solutionName) ||
        solutionList.some((s, i) => i !== index && s.solutionName === solution.solutionName)
      flagArr.push(isDisable)
    })

    setSolutionDisabled(flagArr.includes(true))
  }

  const validateSolution = () => {
    const tempError: any = [...formErr]
    const errorFlags: boolean[] = []
    solutionList?.map((solution: Solution, index: number) => {
      if (!tempError[index]) {
        tempError[index] = { ...defaultError }
      }
      if (!solution?.solutionName || solution?.solutionName?.length == 0) {
        tempError[index].solutionName.error = true
        errorFlags.push(false)
      } else {
        tempError[index].solutionName.error = false
      }

      if (solution.solutionName) {
        const isValidInputErr = isValidInput(solution?.solutionName, 'Solution')
        if (isValidInputErr !== true) {
          tempError[index].solutionName.error = true
          tempError[index].solutionName.message = isValidInputErr
          errorFlags.push(false)
        }
      }

      if (!solution?.apis || solution?.apis?.length == 0) {
        tempError[index].apis.error = true
        errorFlags.push(false)
      } else {
        tempError[index].apis.error = false
      }

      if (!solution?.keyCustomerSegments || solution?.keyCustomerSegments?.length == 0) {
        tempError[index].keyCustomerSegments.error = true
        errorFlags.push(false)
      } else {
        tempError[index].keyCustomerSegments.error = false
      }

      if (!solution?.country || solution?.country?.length == 0) {
        tempError[index].country.error = true
        errorFlags.push(false)
      } else {
        tempError[index].country.error = false
      }

      if (!solution?.category || solution?.category?.length == 0) {
        tempError[index].category.error = true
        errorFlags.push(false)
      } else {
        tempError[index].category.error = false
      }
      if (!solution?.solutionHighlights || solution?.solutionHighlights?.length == 0) {
        tempError[index].solutionHighlights.error = true
        errorFlags.push(false)
      } else {
        tempError[index].solutionHighlights.error = false
      }
    })
    setFormErr(tempError)

    return !errorFlags.includes(false)
  }

  const onSelectChange = (value: any, index: number, key: string) => {
    const tempSolutions: Solution[] = [...solutionList]
    if (value == '<p><br></p>') {
      value = ''
    }
    tempSolutions[index] = { ...tempSolutions[index], [key]: value }
    checkTouched(index, key, value)
    setSolutionList(tempSolutions)
  }

  useEffect(() => {
    if (partner?.solution) {
      setSolutionList(partner?.solution)
    }
  }, [])

  useEffect(() => {
    isDisableSolutionButton()
  }, [solutionList])

  const removeSolution = (index: number) => {
    const tempSolutions = [...solutionList]
    tempSolutions.splice(index, 1)
    setSolutionList(tempSolutions)
  }

  const onSubmit = () => {
    const isValid = validateSolution()
    if (!isValid) {
      return false
    }
    setPartner((preState: any) => {
      return { ...preState, solution: solutionList }
    })

    restProps.next({ ...partner, solution: solutionList })
    return true
  }

  const addNewSolution = () => {
    setSolutionList((preState: any) => [...preState, { ...defaultSolution }])
    setFormErr((preState: any) => [...preState, { ...defaultError }])
  }

  const showSolutionList = () => {
    return solutionList?.map((item: any, index: number) => {
      return (
        <OneSolution
          key={`solution_item_${index}`}
          index={index}
          solution={item}
          onInputChange={onInputChange}
          onSelectChange={onSelectChange}
          formErr={formErr}
          removeSolution={removeSolution}
          masterData={masterData}
        />
      )
    })
  }

  return (
    <>
      <Toast isVisible={isSuccess} text={successMsg} onDismiss={() => setIsSuccess(false)} type="error" />
      <PartnerP>{PartnerStrings.solutionInformation}</PartnerP>
      <StyledLine />
      <RequiredFieldsText>{PartnerStrings.requireFieldText}</RequiredFieldsText>
      {showSolutionList()}
      <div className="steps-action">
        <FormButton type="primary" onClick={addNewSolution}>
          {'Add'}
        </FormButton>
        <Button type="primary" htmlType="submit" disabled={solutionDisabled} onClick={onSubmit}>
          NEXT
        </Button>
      </div>
    </>
  )
}

export default SolutionInfo
