import React, { FC, useState } from 'react'
import {
  AppName,
  InputContainer,
  FormElement,
  FormLabel,
  InputName,
  ButtonsContainer,
  CreateButton,
  MainContainer,
  StyledLine,
  ApiName,
  FormCheckboxesContainer,
  FormCheckboxContainer,
  FormCheckbox,
  CheckboxLabel,
  InputChipContainer,
  ResourceIdContainer,
  ChipContainer,
  SelectAllResource,
} from './Styles'
import { EMAIL_REG_EXP, VALID_URL } from '../../../shared/constants/auth'
import { ErrorStrings } from '../../../shared/strings/AuthFormContent'
import { CreateWebhookForm } from '../../../shared/strings/ApplicationsContent'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage, Toast } from '../../../shared/components'
import { ReactComponent as EyeIcon } from '../../../assets/icons-eye.svg'
import { useMutation } from 'react-query'
import { createSubscription } from '../../../shared/api/webhookAPI'
import { CreateWebhookSubscription } from '../../../shared/models/webhookSubscription'
import { Resources } from '../../../shared/models/resource'
import { CustomerApp } from '../../../shared/models/application'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { EventType } from '../../../shared/models/events'
import {
  escalatorMovementDisableEnvs,
  elvatorDoorButtonDisableEnvs,
  movementStartedDisableEnvs,
  movCompletedFlagDisableEnvs,
} from '../../../shared/constants/common'
import awsConfig from '../../../shared/utils/awsConfig'
type Props = {
  application: CustomerApp
  closeNewWebhookPanel: () => void
  showSuccessfulCreation: () => void
  isHttpUrl: (endpointUrl: string) => void
  resourceData: Resources | undefined
}

const WebhookForm: FC<Props> = ({
  application,
  closeNewWebhookPanel,
  showSuccessfulCreation,
  isHttpUrl,
  resourceData,
}) => {
  const { mutateAsync: createSubscriptionWebhook, isLoading } = useMutation<
    CreateWebhookSubscription,
    unknown,
    CreateWebhookSubscription
  >(createSubscription)

  const [isCreateAppErrorVisible, setIsCreateAppErrorVisible] = useState(false)
  const [typesArray, setTypesArray] = useState<string[]>([])
  const [isTermsChecked, setIsTermsChecked] = useState(false)
  const [submitError, setSubmitError] = useState<Error | undefined>()
  const [subscribedResourceIdsArray, setSubscribedResourceIdsArray] = useState<string[]>([])

  const kenResources = resourceData && resourceData.filter((r: any) => r.resourceId.type === 'ken')

  const {
    handleSubmit,
    control,
    formState: { isDirty, errors: formErrors },
    register,
    reset,
  } = useForm({
    defaultValues: {
      webhookEndpoint: '',
      endpointSecret: '',
      contactEmail: undefined,
      types: typesArray,
      subscriptionName: '',
    },
  })

  const handleCheckboxClick = (e: any, value: string) => {
    if (e.target.checked) {
      setIsTermsChecked(isTermsChecked)
      setTypesArray([...typesArray, value])
    } else {
      setIsTermsChecked(!isTermsChecked)
      setTypesArray(typesArray.filter((item) => item !== value))
    }
  }

  function onResourceIdAdded(data: string) {
    if (!subscribedResourceIdsArray.includes(data)) {
      setSubscribedResourceIdsArray([...subscribedResourceIdsArray, data])
    } else {
      setSubscribedResourceIdsArray(subscribedResourceIdsArray.filter((id) => id !== data))
    }
  }

  const onSubmit = async (data: CreateWebhookSubscription) => {
    setIsCreateAppErrorVisible(false)
    try {
      const webhookEndpoint = data.webhookEndpoint
      const endpointSecret = data.endpointSecret
      const clientId = application.clientId!
      const subscriptionName = data.subscriptionName

      const body = {
        webhookEndpoint,
        endpointSecret,
        types: typesArray,
        subscriptionName,
        subscribedResourceIds: subscribedResourceIdsArray,
      }
      if (data.contactEmail !== '') {
        Object.assign(body, { contactEmail: data.contactEmail })
      }
      await createSubscriptionWebhook({
        clientId,
        body,
      })
      isHttpUrl(webhookEndpoint!)
      closeNewWebhookPanel()
      showSuccessfulCreation()
      reset()
    } catch (err: any) {
      setIsCreateAppErrorVisible(true)
      setSubmitError(err.response.data.message)
    }
  }

  const onSelectAll = (e: CheckboxChangeEvent) => {
    const selectedKens: string[] = []
    if (e?.target?.checked) {
      kenResources?.map((data: any, key: number) => {
        selectedKens.push(`ken:${data.resourceId.uniqueId}`)
      })
    }
    setSubscribedResourceIdsArray(selectedKens)
  }

  return (
    <>
      <Toast
        isVisible={isCreateAppErrorVisible}
        text={submitError}
        onDismiss={() => setIsCreateAppErrorVisible(false)}
        type="error"
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <MainContainer>
          <InputContainer>
            <FormElement>
              <FormLabel htmlFor="webhook-name-input-js">{CreateWebhookForm.webhookName}</FormLabel>
              <Controller
                render={({ field }) => <InputName id="webhook-name-input-js" {...field} />}
                name="subscriptionName"
                control={control}
                rules={{
                  required: true,
                }}
              />
              {formErrors.subscriptionName && (
                <ErrorMessage>
                  {formErrors.subscriptionName.message || CreateWebhookForm.webhookNameRequired}
                </ErrorMessage>
              )}
            </FormElement>
            <FormElement>
              <FormLabel htmlFor="webhook-webhookEndpoint-input-js">{CreateWebhookForm.webhookTargetURL}</FormLabel>
              <Controller
                render={({ field }) => <InputName id="webhook-webhookEndpoint-input-js" {...field} />}
                name="webhookEndpoint"
                control={control}
                rules={{
                  pattern: {
                    value: VALID_URL,
                    message: ErrorStrings.invalidURL,
                  },
                  required: true,
                }}
              />
              {formErrors.webhookEndpoint && (
                <ErrorMessage>
                  {formErrors.webhookEndpoint.message || CreateWebhookForm.webhookUrlRequiredError}
                </ErrorMessage>
              )}
            </FormElement>
          </InputContainer>
          <InputContainer>
            <FormElement>
              <FormLabel htmlFor="webhook-secret-input-js">{CreateWebhookForm.webhookSecret}</FormLabel>
              <Controller
                render={({ field }) => (
                  <InputName.Password id="webhook-secret-input-js" {...field} iconRender={() => <EyeIcon />} />
                )}
                name="endpointSecret"
                control={control}
                rules={{
                  required: true,
                }}
              />
              {formErrors.endpointSecret && (
                <ErrorMessage>
                  {formErrors.endpointSecret.message || CreateWebhookForm.webhookSecretRequiredError}
                </ErrorMessage>
              )}
            </FormElement>
            <FormElement>
              <FormLabel htmlFor="webhook-email-input-js">{CreateWebhookForm.webhookContactEmail}</FormLabel>
              <Controller
                render={({ field }) => <InputName id="webhook-email-input-js" {...field} />}
                name="contactEmail"
                control={control}
                rules={{
                  pattern: {
                    value: EMAIL_REG_EXP,
                    message: ErrorStrings.invalidEmailError,
                  },
                  required: false,
                }}
              />
              {formErrors.contactEmail && <ErrorMessage> {formErrors.contactEmail.message}</ErrorMessage>}
            </FormElement>
          </InputContainer>
        </MainContainer>
        {!application.sandbox && kenResources !== undefined && kenResources.length !== 0 && (
          <InputChipContainer>
            <FormLabel htmlFor="webhook-resource-id-input-js">
              {CreateWebhookForm.subscribeResourceIds}
              <SelectAllResource
                checked={kenResources?.length === subscribedResourceIdsArray?.length}
                onChange={onSelectAll}
              >
                {CreateWebhookForm.webhookSelectAll}
              </SelectAllResource>
            </FormLabel>
            <ChipContainer>
              {kenResources.map((data: any, key: number) => (
                <ResourceIdContainer
                  id={`resource-id-${key}-js`}
                  key={key}
                  type={subscribedResourceIdsArray.includes(`ken:${data.resourceId.uniqueId}`) ? 'primary' : 'default'}
                  onClick={() => onResourceIdAdded(`ken:${data.resourceId.uniqueId}`)}
                  className={'active'}
                >
                  {data.resourceId.uniqueId}
                </ResourceIdContainer>
              ))}
            </ChipContainer>
          </InputChipContainer>
        )}
        <StyledLine />
        <AppName>Available subscriptions</AppName>
        <br />
        <FormCheckboxesContainer>
          <FormCheckboxContainer>
            {
              <FormCheckbox
                id="webhook-test-event-checkbox-js"
                {...register('types', { required: true })}
                readOnly
                onClick={(e) => handleCheckboxClick(e, 'com.kone.test.v1')}
                type="checkbox"
                defaultChecked={false}
              />
            }
            <CheckboxLabel htmlFor="webhook-test-event-checkbox-js">
              {CreateWebhookForm.webhookCheckboxTestEvent} &nbsp;
            </CheckboxLabel>
          </FormCheckboxContainer>
          <br />
          <ApiName>Equipment Status API</ApiName>

          {!movementStartedDisableEnvs.includes(awsConfig?.environment) && (
            <FormCheckboxContainer>
              <FormCheckbox
                id="webhook-movement-started-event-checkbox-js"
                {...register('types', { required: true })}
                readOnly
                onClick={(e) => handleCheckboxClick(e, 'com.kone.elevator.movement.started.v1')}
                type="checkbox"
                defaultChecked={false}
              />
              <CheckboxLabel htmlFor="webhook-movement-started-event-checkbox-js">
                {CreateWebhookForm.webhookCheckboxMovementStartedEvent} &nbsp;
              </CheckboxLabel>
            </FormCheckboxContainer>
          )}

          <FormCheckboxContainer>
            {
              <FormCheckbox
                id="webhook-availability-event-checkbox-js"
                {...register('types', { required: true })}
                readOnly
                onClick={(e) => handleCheckboxClick(e, 'com.kone.equipment.availability.v1')}
                type="checkbox"
                defaultChecked={false}
              />
            }
            <CheckboxLabel htmlFor="webhook-availability-event-checkbox-js">
              {CreateWebhookForm.webhookCheckboxAvailabilityEvent} &nbsp;
            </CheckboxLabel>
          </FormCheckboxContainer>

          <FormCheckboxContainer>
            {
              <FormCheckbox
                id="webhook-equipment-service-event-checkbox-js"
                {...register('types', { required: true })}
                readOnly
                onClick={(e) => handleCheckboxClick(e, 'com.kone.equipment.status.v1')}
                type="checkbox"
                defaultChecked={false}
              />
            }
            <CheckboxLabel htmlFor="webhook-equipment-service-event-checkbox-js">
              {CreateWebhookForm.webhookCheckboxServiceStatusEvent} &nbsp;
            </CheckboxLabel>
          </FormCheckboxContainer>
          {!escalatorMovementDisableEnvs.includes(awsConfig?.environment) && (
            <FormCheckboxContainer>
              {
                <FormCheckbox
                  id="webhook-escalator-movement-change-event-checkbox-js"
                  {...register('types', { required: true })}
                  readOnly
                  onClick={(e) => handleCheckboxClick(e, EventType.EscalatorMovementChangeV1)}
                  type="checkbox"
                  defaultChecked={false}
                />
              }
              <CheckboxLabel htmlFor="webhook-escalator-movement-change-event-checkbox-js">
                {CreateWebhookForm.webhookCheckboxEscMovementChangeEvent} &nbsp;
              </CheckboxLabel>
            </FormCheckboxContainer>
          )}

          {!elvatorDoorButtonDisableEnvs.includes(awsConfig?.environment) && (
            <FormCheckboxContainer>
              {
                <FormCheckbox
                  id="webhook-elevator-doot-event-checkbox-js"
                  {...register('types', { required: true })}
                  readOnly
                  onClick={(e) => handleCheckboxClick(e, 'com.kone.elevator.door.event.v1')}
                  type="checkbox"
                  defaultChecked={false}
                />
              }
              <CheckboxLabel htmlFor="webhook-elevator-doot-event-checkbox-js">
                {CreateWebhookForm.webhookCheckboxElvDoorEvent} &nbsp;
              </CheckboxLabel>
            </FormCheckboxContainer>
          )}
          {!elvatorDoorButtonDisableEnvs.includes(awsConfig?.environment) && (
            <FormCheckboxContainer>
              {
                <FormCheckbox
                  id="webhook-elevator-button-event-checkbox-js"
                  {...register('types', { required: true })}
                  readOnly
                  onClick={(e) => handleCheckboxClick(e, 'com.kone.elevator.button.event.v1')}
                  type="checkbox"
                  defaultChecked={false}
                />
              }
              <CheckboxLabel htmlFor="webhook-elevator-button-event-checkbox-js">
                {CreateWebhookForm.webhookCheckboxElvButtonEvent} &nbsp;
              </CheckboxLabel>
            </FormCheckboxContainer>
          )}

          <FormCheckboxContainer>
            {
              <FormCheckbox
                id="webhook-movement-completed-event-v2-checkbox-js"
                {...register('types', { required: true })}
                readOnly
                onClick={(e) => {
                  handleCheckboxClick(e, 'com.kone.elevator.movement.completed.v1')
                }}
                type="checkbox"
                defaultChecked={false}
              />
            }
            <CheckboxLabel htmlFor="webhook-movement-completed-event-v2-checkbox-js">
              {CreateWebhookForm.webhookCheckboxMovCompTwoEvent} &nbsp;
            </CheckboxLabel>
          </FormCheckboxContainer>

          <br />
          <ApiName>Service Info API</ApiName>
          <FormCheckboxContainer>
            {
              <FormCheckbox
                id="webhook-service-order-event-checkbox-js"
                {...register('types', { required: true })}
                readOnly
                onClick={(e) => handleCheckboxClick(e, 'com.kone.equipment.serviceorder.v2')}
                type="checkbox"
                defaultChecked={false}
              />
            }
            <CheckboxLabel htmlFor="webhook-service-order-event-checkbox-js">
              {CreateWebhookForm.webhookCheckboxServiceOrderEvent} &nbsp;
            </CheckboxLabel>
          </FormCheckboxContainer>
          {formErrors.types && <ErrorMessage>{CreateWebhookForm.webhookTypesRequiredError}</ErrorMessage>}
        </FormCheckboxesContainer>
        <ButtonsContainer>
          <CreateButton
            id="create-webhook-button-js"
            type="primary"
            htmlType="submit"
            disabled={!isDirty}
            loading={isLoading}
          >
            {CreateWebhookForm.createWebhook}
          </CreateButton>
        </ButtonsContainer>
      </form>
    </>
  )
}

export default WebhookForm
