import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogJan25 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        January 2025
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        January release notes summarizes latest changes for Service Info API and Bug fixes.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>
              <q>activityType</q> has been updated with additional values in
              <Link to={Paths.ServiceInfoWebhookAPIApiPageDocs.path}> Service Info Webhook API </Link> and{' '}
              <Link to={Paths.ServiceInfoAPIV2Docs.path + '#getServiceOrderById'}> Service Info REST API</Link> .
            </li>
            <li>
              Description of <q>activityType</q> updated in{' '}
              <Link to={Paths.ServiceInfoAPIV2Docs.path + '#getServiceOrderById'}> Service Info REST API</Link> .
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              Bug related to the <Link to={Paths.Glossary.path}>Glossary</Link> page image has now been fixed.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
